import React from 'react'
import { Link } from 'gatsby'
import Post from 'components/post';
import { Row, Col } from 'react-bootstrap';
import Layout from 'layouts';
import ReactPlayer from 'react-player'
import YouTube from 'react-youtube';

export default (props) => (
  <Layout {...props}>
    {console.log(props)}
    <Row style={{margin: '0px 15px 30px 15px'}}>
      <Col md={{span: 8, offset: 2}}>
        <h3 style={{fontSize: '26px', textAlign: 'center', margin: '50px 0px'}}>Hear how founders weild datasets as tools to accelerate their industries</h3>
        {props.pageContext.interviews.map(interview => <Interview interview={interview} />)}
      </Col>
    </Row>
  </Layout>
)

const Interview = (props) => (
  <div style={{position: 'relative', paddingTop: '50px', paddingBottom: '50px', borderBottom: '2px solid rgba(0, 0, 0, 0.06)', textAlign: 'center'}}>
    <YouTube width='100%' height='100%' videoId={props.interview.ID}/>
    <h3 style={{marginTop: '20px', textAlign: 'center'}}>{props.interview.Name}, {props.interview.Title}</h3>
  </div>
)
